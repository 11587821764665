import { VariantProps, cva } from 'class-variance-authority';

export const button = cva(
  [
    'relative',
    'focus:outline-none',
    'flex',
    'items-center',
    'whitespace-nowrap',
    'text-base',
    'font-medium',
    'transition-colors',
    'gap-x-2',
  ],
  {
    variants: {
      variant: {
        primary: [
          'bg-primary-500',
          'border-transparent',
          'text-white',
          'hover:bg-primary-600',
          'active:bg-primary-700',
          'active:text-white',
          'disabled:bg-grey-100',
          'disabled:text-grey-300',
          'disabled:cursor-not-allowed',
        ],
        secondary: [
          'ring-1',
          'ring-grey-700',
          'text-grey-700',
          'border-transparent',
          'hover:text-grey-700',
          'hover:ring-grey-200',
          'hover:bg-grey-200',
          'active:text-grey-900',
          'active:ring-grey-900',
          'active:bg-grey-200',
          'disabled:text-grey-400',
          'disabled:ring-grey-400',
          'disabled:bg-transparent',
          'disabled:cursor-not-allowed',
        ],
        text: [
          'ring-1',
          'ring-transparent',
          'bg-transparent',
          'border-transparent',
          'text-grey-900',
          'hover:text-grey-700',
          'active:text-grey-700',
          'disabled:text-grey-500',
          'disabled:cursor-not-allowed',
        ],
      },
      size: {
        xs: 'px-4 py-1 text-xs',
        sm: 'px-4 py-1 text-sm',
        md: 'px-5 py-2 text-sm md:text-base',
        lg: 'h-12 px-6 py-3',
      },
      rounded: {
        full: 'rounded-full',
        lg: 'rounded-lg',
      },
      state: {
        loading: '',
        idle: '',
      },
    },
    compoundVariants: [
      {
        variant: 'primary',
        state: 'loading',
        className: 'bg-primary-700 border-primary-700 cursor-wait',
      },
      {
        variant: 'secondary',
        state: 'loading',
        className: 'bg-grey-100 border-grey-900 cursor-wait',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'md',
      rounded: 'full',
      state: 'idle',
    },
  },
);

export type ButtonVariantsProps = VariantProps<typeof button>;
